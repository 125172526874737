/* Drive Colorado Mobile CSS */

:root{
  --mobile-content-width: 90%;
}

@media all and (max-width: 2100px)  {
	/* Stack todays what to expect */
	#weather section.primary #currentWeather #forecast #todayWrapper { flex-direction: column;}
	#weather section.primary #currentWeather #forecast #todayWrapper > div { align-items: center;}
	#weather section.primary #currentWeather #forecast #todayWrapper .chart { display: flex; justify-content: center;}

	/* Stack Snow Report */
	#weather #snow > div { flex-direction: column;}
}

@media all and (max-width: 1500px)  {
	#weather section.primary #currentWeather #forecast .day:not(.today) h3 { font-size: 12px;}
	#weather section.primary #currentWeather #forecast .day #icon { font-size: 50px;}
	#weather section.primary #currentWeather #forecast .day:not(.today) p { font-size: 12px;}
}

@media all and (max-width: 1100px)  {
	header { flex-wrap: wrap; justify-content: space-between; background: var(--white); position: relative; flex-direction: column-reverse;}
	header main { flex: 0 0 100%; }
	header #passHeadingWrapper {flex: 0 0 calc(80% - 40px); padding: 20px;}
	header #navigationWrapper {display: flex; flex-direction: column; text-align: right; flex: 0 0 calc(20% - 40px); padding: 20px; background: var(--white);}
	header #secondaryNavigationWrapper {flex: 0 0 100%; display: flex; padding: 10px 20px 10px 0; background: var(--blue); }
	header #secondaryNavigationWrapper .MuiBox-root button { }
	#secondaryNavigationWrapper span.name { background: transparent; right: 0; text-transform: uppercase; line-height: 1; text-align: left; padding: 0; margin-left: 20px; height: auto;}
	header #secondaryNavigationWrapper .secondaryNavBox { align-content: space-between !important; flex: 0 0 100%; display: flex !important;}
	header #secondaryNavigationWrapper .secondaryNavBox .notificationWrapper { flex: 0 0 70%; display: flex; justify-content: start; flex-direction: row-reverse; align-items: center;}
	header #secondaryNavigationWrapper .secondaryNavBox .buttonWrapper { flex: 0 0 30%; display: flex; justify-content: end;}
	/* header #secondaryNavigationWrapper { display: none;} */
	body.menu__active { overflow: hidden;}
	body.menu__active nav { width: 100%;}
	body.menu__active button.menu__button { position: relative; top: -60px;}

	section.contentWrapper { flex-wrap: wrap; position: relative;}
	section.primary { flex: 0 0 100%; padding: 20px; display: flex; flex-wrap: wrap; display: block !important; overflow-y: scroll !important;}
	section.secondary { flex: 0 0 100%; flex-direction: column-reverse;}
	section.flex { flex: 1; background-image: url('assets/images/drive_colorado_bg.jpg'); background-size: cover; background-position: bottom center; min-height: 500px;}
	section.overview { max-height: none;}

	.mobileNavWrapper { display: flex;}

	#home section.primary h2 { font-size: 110px !important;}
	#home section.primary #key { position: relative; bottom: 0px; left: 0px; font-size: .7em; font-style: italic;}

	/* Forms */
	#login section.primary article form { width: 100%;}

	/* Footer */
	footer { display: block; text-align: center; padding: 10px 20px;}
	footer .ad-wrapper { list-style: none;}

	/* Weather */
	#weather section.primary #currentWeather #forecast .day:not(.today) h3 { font-size: 15px;}
	#weather section.primary #currentWeather #forecast .day #icon { font-size: 70px;}
	#weather section.primary #currentWeather #forecast .day:not(.today) p { font-size: 1rem;}


}

@media all and (max-width: 900px)  {
	/* Cameras */
	#cameras ul li { flex: 0 0 calc((100% / 2) - (20px / 2));}
}

@media all and (max-width: 700px)  {

	ul.conditions li { width: 50px;}
	ul.conditions li svg { width: 50px;}
	ul.conditions li.addReport svg { width: 50px; height: 50px;}

	section.flex { min-height: 300px;}

	/* Stack Weather Forecast */
	#weather section.primary #currentWeather #forecast .day { flex-basis: 100%;}
	#weather section.primary #currentWeather #forecast .day:not(.today) { padding: 35px 0; margin-bottom: 20px;}
	#weather section.primary #currentWeather #forecast .day:not(.today) h3 { font-size: 20px;}
	#weather section.primary #currentWeather #forecast .day #icon { font-size: 100px;}

}

@media all and (max-width: 600px)  {
	.MuiTabs-flexContainerVertical button[role="tab"] { justify-content: left !important; padding-left: 30px !important; max-width: 100%; background: var(--ultra-light-grey) !important;}
	#home .MuiTabs-flexContainerVertical button[role="tab"] { background: transparent !important;}
	.MuiTabs-indicator { right: auto !important; left: 0 !important;}

	.createReport #category button { flex: 0 0 100%;}

	.MuiDialogActions-root { flex-wrap: wrap;}
	.MuiDialogActions-root button { flex: 0 0 100% !important;}


	header #secondaryNavigationWrapper .secondaryNavBox .notificationWrapper { flex: 1;}
	header #secondaryNavigationWrapper .secondaryNavBox .buttonWrapper { flex: 1;}

	/* Stack Weather More */
	#weather section.primary #currentWeather #current > div { flex-direction: column;}
	#weather section.primary #currentWeather #forecast #todayWrapper > div { flex-direction: column; text-align: center;}
	#weather section.primary #currentWeather #forecast .day p.temp { font-size: 30px;}
	#weather section.primary #currentWeather #forecast .day .temp svg { top: 5px;}
	#weather section.primary #currentWeather #forecast #todayWrapper div p.temp.min { margin-top: 20px;}
	#weather section.primary #currentWeather #forecast #todayWrapper div p.temp.max { margin-bottom: 20px;}

	.recharts-wrapper { width: 100% !important;}
	.recharts-legend-wrapper { width: 100% !important;}
	.recharts-surface { width: 100% !important;}
}

@media all and (max-width: 500px)  {

	#home section.primary h2 { font-size: 20vw !important;}
	.createReport button { font-size: 18px;}
}

@media all and (max-width: 415px)  {
	.MuiDialog-root#reportDetails button.down svg { display: none;}
	.MuiDialog-root#reportDetails button.up svg { display: none;}

	section#weather article em { font-size: .8em;}
}

@media all and (max-width: 400px)  {
	header .logo h1, header .logo span { font-size: 18px;}
}